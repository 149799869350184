// Used in both admin and client side

.reference-attributes {
	p {
		font-size: 15px;
		font-weight: 400;
		text-transform: uppercase;
	}

	.attribute-row {
		display: block;
		width: 100%;
		height: 18px;
		margin: 6px auto;
		background-color: #dcf0f1;
	}

	.attribute-row i {
		display: block;
		float: left;
		width: 10%;
		height: 18px;
		background: #8acecf;
		border-left: 1px solid #dcf0f1;
	}
}

.thumbnail {
	max-width: 200px;
	max-height: 200px;
}

.croppic {
	margin: 0 auto;
	form {
		display: none;
	}


	.cropControls i.cropControlCrop {
		background-image: none;
		@extend .btn;
		background-color: black;
		color: white;
		width: auto;
		padding: 5px 15px;
		&:after {
			content: 'Crop & Save Image'; 
		}

		&:hover {
			background-color: lighten(black, 10%);
		}
	}
}

.image-field {
	img {
		margin-right: 10px;
	}

	.actions {
		.btn {
			display: block;
			margin: 10px 0;
		}
	}
}

#loader.modal .modal-dialog {
	color: white;
	text-align: center;
}